import './ResizeHandle.css'

import { useMemo } from 'react'

import { KeyboardArrowDownRounded } from '@mui/icons-material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.handleAxis
 * @param {Object} props.innerRef
 */
function Handle({ innerRef, handleAxis, ...props }) {
  const rotateValue = useMemo(() => {
    switch (handleAxis) {
      case 'se':
        return '-45deg'
      case 'sw':
        return '45deg'
      default:
        return '0deg'
    }
  }, [handleAxis])

  return (
    <span
      ref={innerRef}
      style={{
        color: 'grey',
        transform: `rotate(${rotateValue})`,
      }}
      {...props}
    >
      <KeyboardArrowDownRounded sx={{ fontSize: 18 }} />
    </span>
  )
}

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.handleAxis
 * @param {Object} props.ref
 */
export default function ResizeHandle(handleAxis, ref) {
  return (
    <Handle
      innerRef={ref}
      handleAxis={handleAxis}
      className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
    />
  )
}
