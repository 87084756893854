import { Block, Circle, DeviceUnknown, QuestionMarkRounded } from '@mui/icons-material'
import { Chip, Tooltip } from '@mui/material'

export const DEVICE_AWS_ONLINE = 'ONLINE'
export const DEVICE_AWS_OFFLINE = 'OFFLINE'
export const DEVICE_AWS_UNKNOWN = 'UNKNOWN'
export const DEVICE_AWS_MQTTS_BLOCKED = 'MQTTS_BLOCKED'

export const AWS_STATUS_OPTIONS = [
  { id: DEVICE_AWS_ONLINE, label: 'Online' },
  { id: DEVICE_AWS_OFFLINE, label: 'Offline' },
  { id: DEVICE_AWS_UNKNOWN, label: 'Unknown' },
  { id: DEVICE_AWS_MQTTS_BLOCKED, label: 'MQTTS Blocked' },
]

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {DEVICE_AWS_ONLINE|DEVICE_AWS_OFFLINE|DEVICE_AWS_UNKNOWN|DEVICE_AWS_MQTTS_BLOCKED} props.status
 * @param {'icon'|'chip'} [props.type]
 * @param {number} [props.size]
 */
export default function DeviceAwsStatusIcon({ status, type = 'icon', size = 14 }) {
  const statusData = AWS_STATUS_OPTIONS.find((item) => item.id === status)
  const label = statusData ? `AWS ${statusData.label}` : 'Unknown'

  let Icon = DeviceUnknown
  let iconColor = 'grey'

  switch (status) {
    case DEVICE_AWS_ONLINE: {
      Icon = Circle
      iconColor = 'success.main'
      break
    }
    case DEVICE_AWS_OFFLINE: {
      Icon = Circle
      iconColor = 'error.main'
      break
    }
    case DEVICE_AWS_UNKNOWN: {
      Icon = QuestionMarkRounded
      iconColor = 'grey'
      break
    }
    case DEVICE_AWS_MQTTS_BLOCKED: {
      Icon = Block
      iconColor = 'error.main'
      break
    }
    default: {
      Icon = DeviceUnknown
      iconColor = 'grey'
    }
  }

  return type === 'chip' ? (
    <Chip
      icon={<Icon sx={{ '&&': { color: iconColor } }} />}
      variant="outlined"
      size="small"
      label={label}
      sx={{ color: iconColor, borderColor: iconColor, userSelect: 'none' }}
    />
  ) : (
    <Tooltip placement="top" title={label}>
      <Icon sx={{ fontSize: size, color: iconColor }} />
    </Tooltip>
  )
}
