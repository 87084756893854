import { Box, Link, Tooltip, Typography } from '@mui/material'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.label
 * @param {string} props.value
 * @param {string} [props.linkTo]
 * @param {string} [props.color]
 * @param {Object} [props.HeaderIcon]
 * @param {string} [props.headerIconHint]
 */
export default function HeaderItem({
  label,
  value,
  linkTo = undefined,
  color = undefined,
  HeaderIcon = undefined,
  headerIconHint = undefined,
}) {
  return (
    <Typography variant="body2" color={color}>
      <Box
        component="span"
        fontWeight="fontWeightMedium"
        display="flex"
        alignItems="center"
        gap={0.5}
      >
        {label}
        {HeaderIcon && (
          <Tooltip
            title={
              headerIconHint ? (
                <div style={{ whiteSpace: 'pre-line' }}>{headerIconHint}</div>
              ) : null
            }
          >
            <HeaderIcon sx={{ fontSize: 16 }} />
          </Tooltip>
        )}
      </Box>
      {linkTo ? (
        <Link href={linkTo} sx={{ color }}>
          {value}
        </Link>
      ) : (
        value
      )}
    </Typography>
  )
}
