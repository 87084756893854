/* eslint-disable react/no-array-index-key */
import React from 'react'

import { type } from 'ramda'

import { Box, Divider, Typography } from '@mui/material'

import { titleize, underscore } from 'inflection'
import { DateTime } from 'luxon'

import { useSmallScreen } from '@common/utils'
import { formatAsMacAddress } from '@common/utils/formatters'

import DetailItem from './DetailItem'

/**
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} [props.label]
 * @param {Object|Object[]} props.data
 */
export default function NestedDetailItem({ label = '', data }) {
  const isSmallScreen = useSmallScreen()

  const renderValue = (value, key = '') => {
    const textStyle = {
      variant: 'h6',
      whiteSpace: 'pre-line',
      sx: { fontSize: isSmallScreen ? 14 : 20 },
    }
    const formattedMacAddress = key === 'mac' ? formatAsMacAddress(value) : value
    const dateTime = DateTime.fromISO(value)
    if (dateTime.isValid) {
      return (
        <Typography {...textStyle}>
          {dateTime.toLocaleString(DateTime.DATETIME_FULL)}
        </Typography>
      )
    }
    return <Typography {...textStyle}>{formattedMacAddress ?? value}</Typography>
  }

  return (
    <Box>
      {label && (
        <Typography
          variant="body2"
          color="text.secondary"
          textTransform="uppercase"
          sx={{ fontSize: isSmallScreen ? 10 : 14 }}
        >
          {titleize(underscore(label))}:
        </Typography>
      )}

      {type(data) === 'Object' &&
        Object.entries(data).map(([key, value], i) => (
          <Box
            key={key}
            sx={{
              borderLeft: '1px solid lightgrey',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              margin: `0 0 0 16px`,
              padding: `${i > 0 ? 16 : 4}px  0 0 16px`,
            }}
          >
            <DetailItem
              key={key}
              label={titleize(underscore(key))}
              value={value}
              renderValue={(v) => renderValue(v, key)}
            />
          </Box>
        ))}

      {type(data) === 'Array' &&
        data.map((value, i) => {
          if (type(value) === 'Object') {
            return (
              <React.Fragment key={`${underscore(label)}_${i}`}>
                <NestedDetailItem
                  key={value}
                  label={i > 0 ? label : null}
                  data={value}
                />
                {i < data.length - 1 && <Divider sx={{ mb: 1, mt: 1 }} />}
              </React.Fragment>
            )
          }

          if (type(value) === 'Array') {
            return (
              <Box
                key={`${underscore(label)}_${i}`}
                display="flex"
                flexDirection="column"
              >
                {value.map((v) => renderValue(v))}
              </Box>
            )
          }

          return renderValue(value)
        })}
    </Box>
  )
}
